.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.password {
  height: 100vh;
}

.main-page {
  padding: 2em;
}

body {
  color: black;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  line-height: 1.6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}


section {
  box-shadow: -4px 4px black;
  border: 1px solid black;
  border-radius: 12px;
  box-sizing: border-box;
  margin: 28px auto;
  padding: 28px;
  max-width: 600px;
  width: 100%;
}

h1 {
  font-family: "Bungee Shade", cursive;
  font-size: 36pt;
  line-height: 1.2em;
  margin: 0 auto 28px;
}

h2 {
  font-family: "Bebas Neue", cursive;
  font-size: 24pt;
  margin: 0px auto 18px;
  text-align: left;
  color: black;
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 36pt;
    margin-top: 28px;
  }
}

button {
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 12px;
  background: transparent;
  border: 1px solid rgb(170, 0, 0);
  box-shadow: -4px 4px rgb(170, 0, 0);
  color: rgb(170, 0, 0);
}

iframe {
  width: 100%;
  border-radius: 12px;
}

button:active {
  transform: translate(-2px, 2px);
  color: rgb(170, 0, 0);
}

input {
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 12px;
  background: transparent;
  border: 1px solid black;
  box-shadow: -4px 4px black;
  text-align: center;
}

ul {
  text-align: left;
}

h3 {
  text-transform: uppercase;
  font-size: 8pt;
  margin: 0;
  text-align: right;
}

h4, table {
  text-align: left;
}

td {
  padding-right: 12px;
}

